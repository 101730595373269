"use client";
import { useEffect, useRef, useState } from "react";
import {
  AppModal,
  AvatarIcon,
  CardContainer,
  ContentCatalogue,
  LearningInfoCard,
  ProductInfoCard,
  UserWidget,
  MultiSelectDropdown,
  AppSingleSelectDropdown,
  RangeSliderComponent,
  ToastAlert,
  Badges,
  RadioButtonGroup,
  ProgressBar,
  ToolTip,
  Steppers,
  InputText,
  StaticText,
  CollapseExpandText,
  SplitButton,
  AccordionComponent,
  TimeSelect,
  AppNumberInput,
  FrequencyModule,
  ModalSelector,
  AppFormLabel,
  CoupleRadioWithDropdown,
  DatePicker,
  CommonTitle,
  ConfigurationStatusComponent,
  FeatureTooltip,
  GeneralizedTooltip,
  GuidedTooltip,
  Loader,
} from "./common";
import {
  Box,
  Button,
  ButtonGroup,
  CheckIcon,
  HStack,
  Heading,
  Stack,
  VStack,
} from "./ChakraUiManager";
import {
  CONTENT_CATALOGUE_1,
  CURRENCY_DATA,
  LEARNING_INFO_CARD_PROPS_1,
  LEARNING_INFO_CARD_PROPS_2,
  LEARNING_INFO_CARD_PROPS_3,
  MODAL_DATA_1,
  MODAL_DATA_2,
  MODAL_DATA_3,
  MULTI_SELECT_PROP,
  MULTI_SELECT_PROP_WITH_ARR_OBJ,
  PRODUCT_INFO_CARD_PROPS_1,
  RADIO_DATA_LIST,
  RANGE_SLIDER_PROPS,
  TEST_COMPONENT_GUIDED_TOOLTIP_STEPS,
} from "./data/data";
import Link from "next/link";
import { SplitBtnInputValType } from "./common/split-button/SplitButton";
import { SUMMARY_LANDING_SCREEN_STATUS } from "@/utils";

const TestComponent = () => {
  //Check if redirect from ALM and route to academy page

  const currRef1 = useRef<any>();
  const currRef2 = useRef<any>();
  const currRef3 = useRef<any>();

  // For Input Text
  const [inputText, setInputText] = useState("");
  const [selectedTime, setTime] = useState("");
  const [numberInputValue, setNumberInputValue] = useState("");
  const [numberInputValuePrefix, setNumberInputValuePrefix] = useState("");
  const [singleSelect, setSingleSelect] = useState<any>("");
  const [multiSelectValue1, setMultiSelect1] = useState<string[]>([]);
  const [multiSelectValue2, setMultiSelect2] = useState<{ name: string }[]>([]);
  const [multiSelectValue3, setMultiSelect3] = useState<string[]>([]);
  const [splitButtonValue, setSplitButtonValue] =
    useState<SplitBtnInputValType>(undefined);

  // set values for Multiselect
  const multiSelectValues = (val: any) => {
    setMultiSelect3([...val]);
  };
  const [radioValue, setRadioValue] = useState("");
  const [coupleDropdownOption, setCoupleDropdownOption] = useState("");

  // For Frequency Module State
  const [frequencyModuleValue, setFrequencyModuleValue] = useState("1M5A");

  function showModal(refType: any) {
    if (refType?.current) {
      refType.current.openModal();
    }
  }

  // Handle Text Input
  const handleTextField = (value: string) => {
    setInputText(value);
  };

  function ContentCatalogueFunc(prop?: any) {
    if (prop) {
      console.log(prop);
    }
  }
  const handleButtonClick = () => {
    console.log("Button clicked");
  };

  const [selectedDate, setDate] = useState<Date | null>(null);
  const [currencySingle, setCurrency] = useState("");
  const [currencyMulti, setCurrencyMulti] = useState([]);

  const steps = [
    { title: "Label 1" },
    { title: "Label 2" },
    { title: "Label 3" },
    { title: "Label 4" },
    { title: "Label 5" },
  ];
  const steps2 = [
    { title: "Label 1" },
    { title: "Label 2" },
    { title: "Label 3" },
    { title: "Label 4" },
  ];

  const handleDeleteFromAccordion = () => {
    console.log("Delete Icon From Accordion Clicked");
  };

  return (
    <>
      <CardContainer>
        <Link href="/clover">Go To Clover Setup</Link>
      </CardContainer>
      <CardContainer>
        <Heading as="h2">Learning Info Card:</Heading>
        <Box display="flex" justifyContent="space-between">
          <LearningInfoCard
            styleProps={{ marginRight: "1rem" }}
            {...LEARNING_INFO_CARD_PROPS_1}
          ></LearningInfoCard>
          <LearningInfoCard {...LEARNING_INFO_CARD_PROPS_2}></LearningInfoCard>
        </Box>
        <Box display="flex">
          <LearningInfoCard
            {...LEARNING_INFO_CARD_PROPS_3}
            splitImage={true}
            styleProps={{ marginRight: "1rem" }}
          ></LearningInfoCard>
          <LearningInfoCard
            {...LEARNING_INFO_CARD_PROPS_2}
            disabled={true}
          ></LearningInfoCard>
        </Box>
      </CardContainer>

      <CardContainer>
        <Heading as="h2">Product Info Card:</Heading>
        <ProductInfoCard {...PRODUCT_INFO_CARD_PROPS_1} />
      </CardContainer>

      <CardContainer>
        <Heading as="h2">Avatars:</Heading>
        <Stack my="2rem" direction="row" spacing={4}>
          <AvatarIcon type="circle" />
          <AvatarIcon type="square" />
          <AvatarIcon type="none" />
        </Stack>
      </CardContainer>
      <CardContainer>
        <Heading mb="1.5rem">Button Groups:</Heading>
        <ButtonGroup>
          <Button
            className="app-btn-reg-primary"
            onClick={showModal.bind(this, currRef1)}
          >
            Open Modal
          </Button>
          <Button
            className="app-btn-reg-secondary"
            onClick={showModal.bind(this, currRef2)}
          >
            Modal with landscape Image
          </Button>
          <Button
            className="app-btn-inverse-primary"
            onClick={showModal.bind(this, currRef3)}
          >
            Modal with row Image
          </Button>
          <Button className="app-btn-inverse-secondary">Button Inverse</Button>
          <Button isDisabled={true} className="app-btn-reg-primary">
            Show Modal
          </Button>
        </ButtonGroup>
        <AppModal ref={currRef1} {...MODAL_DATA_1}></AppModal>
        <AppModal ref={currRef2} {...MODAL_DATA_2}>
          <p>This is children</p>
        </AppModal>
        <AppModal ref={currRef3} {...MODAL_DATA_3}></AppModal>
      </CardContainer>
      <CardContainer>
        <ContentCatalogue
          {...CONTENT_CATALOGUE_1}
          linkFunc={ContentCatalogueFunc}
          columnPerRow={3}
        ></ContentCatalogue>
      </CardContainer>
      <CardContainer>
        <Heading as="h2">Learning User Widget:</Heading>
        <UserWidget
          name="Your Name"
          badges={0}
          courses={0}
          onButtonClick={handleButtonClick}
        />
      </CardContainer>
      <CardContainer>
        <MultiSelectDropdown
          {...MULTI_SELECT_PROP}
          values={multiSelectValue1}
          onChange={setMultiSelect1}
        />
        <MultiSelectDropdown
          {...MULTI_SELECT_PROP_WITH_ARR_OBJ}
          values={multiSelectValue2}
          onChange={setMultiSelect2}
        />
        <MultiSelectDropdown
          {...MULTI_SELECT_PROP}
          dropdownName="Multi Select Checkbox"
          showCheckbox={true}
          values={multiSelectValue3}
          onChange={multiSelectValues}
        />
        <span>Single Select</span>
        <AppSingleSelectDropdown
          {...MULTI_SELECT_PROP}
          value={singleSelect}
          onChange={setSingleSelect}
          placeholder="Select"
        ></AppSingleSelectDropdown>
      </CardContainer>
      <CardContainer>
        <RangeSliderComponent {...RANGE_SLIDER_PROPS} />
      </CardContainer>
      <CardContainer>
        <Heading as="h2">Toast Alerts:</Heading>
        <br />
        <div className="toast-alert-containers">
          <ToastAlert
            status="success"
            title="Success"
            description="Your case has been created."
          />
          <br />
          <ToastAlert
            status="success"
            title="Success"
            description="Your case is created"
            bgIconColor="green"
            icon={CheckIcon}
          />
          <br />
          <ToastAlert
            status="success"
            title="Success"
            description="Your case is created"
            bgIconColor="yellow"
            icon={CheckIcon}
          />
          <br />
          <ToastAlert
            status="success"
            title="Success"
            description="Your case is created"
            bgIconColor="red"
            icon={CheckIcon}
          />
          <br />
          <ToastAlert
            status="success"
            title="Success"
            description="Your case is created"
            isDisabled={true}
          />
          <br />
          <ToastAlert
            status="success"
            title="Success"
            description="Your case is created"
            bgIconColor="green"
            icon={CheckIcon}
            isDisabled={true}
          />
          <br />
          <ToastAlert
            status="success"
            title="Success"
            description="Your case is created"
            bgIconColor="yellow"
            icon={CheckIcon}
            isDisabled={true}
          />
          <br />
          <ToastAlert
            status="success"
            title="Success"
            description="Your case is created"
            bgIconColor="red"
            icon={CheckIcon}
            isDisabled={true}
          />
        </div>
      </CardContainer>
      <CardContainer>
        <Heading as="h2">Badges:</Heading>
        <br />
        <VStack align="left">
          <p>Badges Without Icons:</p>
          <HStack>
            <Badges
              size="lg"
              variant="subtle"
              colorScheme="cyan"
              label="Completed"
              type="success"
            />
            <Badges
              size="lg"
              variant="outline"
              colorScheme="yellow"
              label="In Progress"
              type="warning"
            />
            <Badges
              size="lg"
              variant="solid"
              colorScheme="gray"
              label="Not Started"
              type="default"
            />
            <Badges
              size="lg"
              variant="subtle"
              colorScheme="pink"
              label="Error"
              type="error"
            />
          </HStack>
          <br />
          <p>Badges With Icons:</p>
          <HStack>
            <Badges
              size="lg"
              variant="subtle"
              colorScheme="cyan"
              label="Completed"
              icon={true}
            />
            <Badges
              size="lg"
              variant="subtle"
              colorScheme="yellow"
              label="In Progress"
              icon={true}
            />
            <Badges
              size="lg"
              variant="subtle"
              colorScheme="pink"
              label="Error"
              icon={true}
            />
            <Badges
              size="lg"
              variant="subtle"
              colorScheme="gray"
              label="Close"
              icon={true}
            />
          </HStack>
        </VStack>
      </CardContainer>
      <CardContainer>
        <div className="progress-bar-width">
          <Heading as="h2">Progress Bar:</Heading>
          <br />
          Default:
          <ProgressBar value={20} />
          <br />
          Focus: <ProgressBar value={40} focus={true} />
          <br />
          Disabled:-
          <ProgressBar value={60} disabled={true} />
        </div>
      </CardContainer>
      <CardContainer>
        <Heading as="h2">Radio Buttons:</Heading>
        <RadioButtonGroup
          value={radioValue}
          onChange={setRadioValue}
          radioPropList={RADIO_DATA_LIST}
          stackDirection={"column"}
          spacing={"0.8rem"}
        />
        <br />
        <RadioButtonGroup
          isBoxedRadio={true}
          value={radioValue}
          onChange={setRadioValue}
          radioPropList={RADIO_DATA_LIST}
          stackDirection={"column"}
          spacing={"1rem"}
        />
        <br />
        <div className="progress-bar-width">
          <Heading as="h2">Progress Bar:</Heading>
          <br />
          Default:
          <ProgressBar value={20} />
          <br />
          Focus: <ProgressBar value={40} focus={true} />
          <br />
          Disabled:-
          <ProgressBar value={60} disabled={true} />
        </div>
      </CardContainer>
      <CardContainer>
        <Heading as="h2">ToolTip:</Heading>
        Default:-
        <ToolTip
          content={"Tooltip Text"}
          placement={"left"}
          IconDefault={true}
        />
        <br />
        Deactivated:-
        <ToolTip
          content={"Tooltip Text"}
          placement={"bottom"}
          IconDeactivated={true}
        />
        <br />
        Top:-
        <ToolTip
          content={"Tooltip Text"}
          placement={"top"}
          IconDefault={true}
        />
        <br />
        Left:-
        <ToolTip
          content={"Tooltip Text"}
          placement={"left"}
          IconDefault={true}
        />
        <br />
        Right:-
        <ToolTip
          content={"Tooltip Text"}
          placement={"right"}
          IconDefault={true}
        />
        <br />
        Bottom:-
        <ToolTip
          content={"Tooltip Text"}
          placement={"bottom"}
          IconDefault={true}
        />
        <br />
      </CardContainer>
      <CardContainer>
        <Heading as="h2">Steppers:</Heading>
        Horizontal Stepper:-
        <br />
        <Steppers orientation={"horizontal"} steps={steps} activeIndex={1} />
        <div className="stepper-width-sizing1">
          <Steppers orientation={"horizontal"} steps={steps2} activeIndex={1} />
        </div>
        <div className="stepper-width-sizing2">
          <Steppers
            orientation={"horizontal"}
            steps={[
              { title: "Label 1" },
              { title: "Label 2" },
              { title: "Label 3" },
            ]}
            activeIndex={1}
          />
        </div>
        <div className="stepper-width-sizing3">
          <Steppers
            orientation={"horizontal"}
            steps={[{ title: "Label 1" }, { title: "Label 2" }]}
            activeIndex={1}
          />
        </div>
        <div className="stepper-width-sizing3">
          <Steppers
            orientation={"horizontal"}
            steps={[{ title: "Label 1" }]}
            activeIndex={1}
          />
        </div>
      </CardContainer>
      <CardContainer>
        Vertical Stepper:-
        <Steppers orientation={"vertical"} steps={steps} activeIndex={1} />
      </CardContainer>
      {/* Input Text Components */}
      <CardContainer>
        Custom Input Field
        <InputText value={inputText} onChange={handleTextField} />
      </CardContainer>
      <CardContainer>
        Custom Staic Field
        <StaticText textValue="14" />
      </CardContainer>
      <CardContainer>
        Collapsible-Expand
        <CollapseExpandText title="Naming Rules">
          <ToastAlert
            status="success"
            title="Success"
            description="Your case has been created."
          />
        </CollapseExpandText>
      </CardContainer>
      <CardContainer>
        <SplitButton
          onSelect={setSplitButtonValue}
          leftBtnName="Yes"
          rightBtnName="No"
          value={splitButtonValue}
        />
      </CardContainer>
      <CardContainer>
        <AccordionComponent
          accordionTitle="Accordion Title Goes Here"
          limitName="[Limit Name]"
          deleteIcon={true}
          onDeleteHandler={handleDeleteFromAccordion}
          isExpand={1}
        >
          <CardContainer>
            Custom Input Field
            <InputText
              value={inputText}
              onChange={handleTextField}
              placeholder="placeholder"
            />
          </CardContainer>
          <CardContainer>
            Custom Staic Field
            <StaticText textValue="14" />
          </CardContainer>
        </AccordionComponent>
      </CardContainer>
      <CardContainer>
        <TimeSelect value={selectedTime} onChange={setTime} />
      </CardContainer>
      <CardContainer>
        <AppNumberInput
          value={numberInputValue}
          labelName="Without Prefix"
          onChange={setNumberInputValue}
        />
        <AppNumberInput
          value={numberInputValuePrefix}
          labelName="With Prefix"
          onChange={setNumberInputValuePrefix}
          valuePrefix="$"
        />
      </CardContainer>
      <CardContainer>
        <FrequencyModule
          value={frequencyModuleValue}
          onChange={setFrequencyModuleValue}
          frequencyQuestionTitle="What is the next change frequency?"
        />
      </CardContainer>
      <CardContainer>
        <AppFormLabel labelName={"Single Select Modal"} />
        <ModalSelector
          value={currencySingle}
          linkName={"Select Currency"}
          modalTitle={"Select the currency code"}
          optionList={CURRENCY_DATA}
          onChange={setCurrency}
        />
      </CardContainer>
      <CardContainer>
        <AppFormLabel labelName={"Multi Select Modal"} />
        <ModalSelector
          value={currencyMulti}
          linkName={"Select Currency"}
          modalTitle={"Select the currency code"}
          optionList={CURRENCY_DATA}
          isSingleSelect={false}
          onChange={setCurrencyMulti}
        />
      </CardContainer>

      {/* Date Picker */}
      <CardContainer styleProps={{ gap: "10px" }}>
        <span>Basic</span>
        <DatePicker value={selectedDate} onUpdate={setDate} />
        <span>Error</span>
        <DatePicker error value={selectedDate} onUpdate={setDate} />
        <span>Small</span>
        <DatePicker small value={selectedDate} onUpdate={setDate} />
      </CardContainer>
      <CardContainer>
        <AppFormLabel
          labelName="Couple Radio with Dropdown"
          labelFor="single-select_input"
        />
        <CoupleRadioWithDropdown
          primaryRadioName={"Choose existing fee"}
          secondaryRadioName={"Add new"}
          handlePrimaryOptionSelect={setCoupleDropdownOption as any}
          placeHolderName="Select existing fee"
          dropdownList={[
            "Option 1",
            "Option 2",
            "Option 3",
            "Option 4",
            "Option 5",
            "Option 6",
            "Option 7",
          ]}
          id={"single-select"}
        />
      </CardContainer>
      <CardContainer>
        <StaticText heading headingName="Guided Tooltips" />
        {/*For guided tooltip we can target element with class , id or element*/}
        <GuidedTooltip tooltipSteps={TEST_COMPONENT_GUIDED_TOOLTIP_STEPS}>
          <Box
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <span id="right">Right:-</span>
            <span id="top">Top:-</span>
            <span id="bottom">Bottom:-</span>
            <span id="left">Left:-</span>
          </Box>
        </GuidedTooltip>
      </CardContainer>
      <div>
        <CommonTitle
          depositName="interest (deposit)"
          featureName="[feature name]"
          version="version 1"
          mainTitle="Indexed Rate - Foundational Details"
          subText="Lorem ipsum dolor sit amet, consectetur adipiscing elit"
        />
      </div>

      <Box width="1141px">
        <ConfigurationStatusComponent
          title="Product Configuration"
          status={SUMMARY_LANDING_SCREEN_STATUS.completed}
          leftBadgeLabel="Foundational Setup"
          leftBadgeStatus={SUMMARY_LANDING_SCREEN_STATUS.in_progress}
          rightBadgeLabel="Test & Deploy"
          rightBadgeStatus={SUMMARY_LANDING_SCREEN_STATUS.not_started}
        />
      </Box>

      <CardContainer>
        <StaticText heading headingName="Genralized Tooltips" />
        <Box style={{ display: "flex", columnGap: "16px", padding: "5rem" }}>
          <br />
          <GeneralizedTooltip
            placement="top"
            content="Business savings lorem ipsum dolor sit amet lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
          >
            Top:-
          </GeneralizedTooltip>
          <br />
          <GeneralizedTooltip
            placement="bottom"
            content="Business savings lorem ipsum dolor sit amet lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
          >
            Bottom:-
          </GeneralizedTooltip>
          <br />
          <GeneralizedTooltip
            placement="right"
            content="Business savings lorem ipsum dolor sit amet lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
          >
            Right:-
          </GeneralizedTooltip>
          <br />
          <GeneralizedTooltip
            placement="left"
            content="Business savings lorem ipsum dolor sit amet lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
          >
            Left:-
          </GeneralizedTooltip>
          <br />
        </Box>
      </CardContainer>
      <CardContainer styleProps={{ paddingBottom: "10rem" }}>
        <StaticText heading headingName="Feature Tooltips" />
        <Box
          style={{
            display: "flex",
            justifyContent: "space-between",
            padding: "0 20rem",
          }}
        >
          <FeatureTooltip
            placement="left"
            content="Complete your Institutional Configuration setup next as part of your console onboarding."
          >
            Left:-
          </FeatureTooltip>
          <FeatureTooltip
            placement="bottom"
            content="Complete your Institutional Configuration setup next as part of your console onboarding."
          >
            Bottom:-
          </FeatureTooltip>

          <FeatureTooltip
            placement="top"
            content="Complete your Institutional Configuration setup next as part of your console onboarding."
          >
            Top:-
          </FeatureTooltip>

          <FeatureTooltip
            placement="right"
            content="Complete your Institutional Configuration setup next as part of your console onboarding."
          >
            Right:-
          </FeatureTooltip>
        </Box>
      </CardContainer>
      <Box m={"82px"}>
        <Loader isOverlay={false} spinnerColor="#222" spinnerSize="40px" />
      </Box>
    </>
  );
};

export default TestComponent;
